<template>
  <s-main-panel v-if="loaded" toolbar :scrollbar="true">
    <template v-slot:actions>
      <v-spacer />
      <v-btn color="info" tile dark @click="downloadBudget()">Télécharger Budget</v-btn>
    </template>
    <v-container fluid>
      <v-row v-if="!editing">
        <v-col cols="12"></v-col>
      </v-row>
    </v-container>
  </s-main-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'association-detail',
  components: {},
  data() {
    return {
      editing: false,
    }
  },
  computed: {
    ...mapGetters({
      getAssociationById: 'etablissement/getAssociationById',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return this.association !== undefined
    },
    associationId() {
      return parseInt(this.$route.params.associationId, 10)
    },
    association() {
      return this.getAssociationById(this.associationId)
    },
  },
  methods: {
    ...mapActions({
      fetchBudget: 'etablissement/fetchBudget',
    }),
    downloadBudget() {
      this.fetchBudget(this.associationId)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
