import axios from 'axios'

export default {
  namespaced: true,
  state: {
    associations: [],
    etablissements: [],
  },
  mutations: {
    ADD_ASSOCIATION(state, _association) {
      state.associations.push(_association)
    },
    SET_ASSOCIATIONS(state, _associations) {
      state.associations = _associations
    },
    ADD_ETABLISSEMENT(state, _etablissement) {
      state.etablissements.push(_etablissement)
    },
    UPDATE_ETABLISSEMENT(state, _etablissement) {
      for (let i = 0; i < state.etablissements.length; i++) {
        if (state.etablissements[i].etablissementId === _etablissement.etablissementId) {
          state.etablissements.splice(i, 1, _etablissement)
          break
        }
      }
    },
    SET_ETABLISSEMENTS(state, _etablissements) {
      state.etablissements = _etablissements
    },
  },
  getters: {
    getEtablissementById: (state) => (_etablissementId) => {
      return state.etablissements.find((etablissement) => etablissement.etablissementId === _etablissementId)
    },
    getEtablissements(state) {
      return state.etablissements.sort(function (sA, sB) {
        return sA.etablissementId - sB.etablissementId
      })
    },
    getSelectableEtablissements: (state) => {
      return (
        state.etablissements
          /*.filter(
          (et) =>
            rootGetters['security/isInRole']('SALARIE_' + et.etablissementId) ||
            rootGetters['security/isInRole']('SALARIE_' + et.siegeSocialId)
        )*/
          .sort(function (sA, sB) {
            return sA.etablissementId - sB.etablissementId
          })
      )
    },
    getAssociations(state) {
      return state.associations.sort(function (sA, sB) {
        return sA.associationId - sB.associationId
      })
    },
    getAssociationById: (state) => (_associationId) => {
      return state.etablissements.find((association) => association.associationId === _associationId)
    },
  },
  actions: {
    fetchAssociations({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/association`).then((response) => {
        commit('SET_ASSOCIATIONS', response.data)
      })
    },
    // eslint-disable-next-line no-empty-pattern
    fetchBudget({}, _associationId) {
      return axios
        .get(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/association/${_associationId}/budget_heures_supp_jour_ferie_annuel.csv?_annee=2024`
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'budget_heures_supp_jour_ferie_annuel.csv'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
    fetchEtablissements({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement`).then((response) => {
        commit('SET_ETABLISSEMENTS', response.data)
        if (response.data.length === 1) {
          this.dispatch('application/selectEtablissement', response.data[0])
        }
      })
    },
    // eslint-disable-next-line no-empty-pattern
    obtenirLeNumeroDastreinteActuellement({}, _etablissementId) {
      return axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/${_etablissementId}/numero-astreinte`)
        .then((response) => response.data)
    },
    createAssociation({ commit }, _toCreate) {
      return axios
        .post(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/association`, _toCreate)
        .then((response) => {
          commit('ADD_ASSOCIATION', response.data)
        })
    },
    createEtablissement({ commit }, _toCreate) {
      return axios.post(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement`, _toCreate).then((response) => {
        commit('ADD_ETABLISSEMENT', response.data)
      })
    },
    updateDirecteurEtablissement({ commit }, _command) {
      return axios
        .put(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/${_command.etablissementId}/directeur`, _command)
        .then((response) => {
          commit('UPDATE_ETABLISSEMENT', response.data)
        })
    },
    async obtenirListeComptablesEtablissement(state, _etablissementId) {
      return await axios
        .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/${_etablissementId}/comptable`)
        .then((response) => {
          return {
            estReussi: response.status === 200,
            comptables: response.data,
          }
        })
    },
    async ajouterUnComptableAEtablissement(state, _ajouterUnComptableAEtablissementCommand) {
      await axios
        .post(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/${_ajouterUnComptableAEtablissementCommand.etablissementId}/comptable`,
          _ajouterUnComptableAEtablissementCommand
        )
        .then((response) => {
          return response.status === 200
        })
    },
    async retirerUnComptableAEtablissement(state, _retirerUnComptableAEtablissementCommand) {
      await axios
        .delete(
          `${process.env.VUE_APP_URL_BACK_SERENITY}/etablissement/${_retirerUnComptableAEtablissementCommand.etablissementId}/comptable`,
          {
            data: _retirerUnComptableAEtablissementCommand,
          }
        )
        .then((response) => {
          return response.status === 200
        })
    },
  },
}
